import React from 'react';
import TrustPilotPluginV1 from './TrustPilotPluginV1';
import TrustPilotPluginV2 from './TrustPilotPluginV2';
import TrustPilotPluginV3 from './TrustPilotPluginV3';

const TrustPilotPlugin = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version2':
      return <TrustPilotPluginV2 {...props} />;
    case 'version3':
      return <TrustPilotPluginV3 {...props} />;
    default:
    case 'version1':
      return <TrustPilotPluginV1 {...props} />;
  }
};

export default TrustPilotPlugin;
