import styled from 'styled-components';

export const TrustPilotContainer = styled.div`
  color: #0b0b0b;
  @media only screen and (max-width: 630px) {
    margin: 0;
  }
`;

export const TrustPilotBadge = styled.div`
  cursor: pointer;
  padding-inline: 6px;
`;

export const HomeHeroTrustpilot = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TrustPilotBadgeTop = styled.div`
  display: flex;
  align-items: center;
`;

export const TrustPilotBadgeLogo = styled.div`
  width: 100px;
  margin-right: 8px;

  svg {
    width: 100%;
    height: auto;
  }
`;
export const TrustPilotRating = styled.div`
  display: flex;
  width: 80px;
`;
export const TrustPilotRatingStarLast = styled.div`
  position: relative;
  width: 20px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  background-color: #e3e3e3;
  margin-right: 0;

  svg {
    position: relative;
    z-index: 1;
    width: 14px;
    height: 14px;
  }
`;

export const TrustPilotRatingStar = styled.div`
  position: relative;
  width: 20px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  background-color: #dcdce6;

  svg {
    position: relative;
    z-index: 1;
    width: 14px;
    height: 14px;
  }
`;

export const TrustPilotRatingBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  ${({ bg, bgColor = `#DCDCE6` }) => {
    switch (bg) {
      case 'half':
        return `
          width: 50%;
          background-color: ${bgColor};
        `;
      case 'whole':
        return `
          background-color: ${bgColor};
        `;
      default:
        return ``;
    }
  }}
`;

export const TrustPilotRatingBgLast = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 50%;
  height: 100%;
  background-color: #00b67a;
`;

export const TrustPilotBadgeText = styled.p`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 6px 0 6px 0;
  color: #000000;
  text-align: center;
  strong {
    font-weight: bolder;
    color: #363636;
  }
  @media only screen and (max-width: 630px) {
    margin: 3px 0 6px 0;
  }
`;
