import styled from 'styled-components';

export const TrustPilotContainer = styled.div`
  color: #0b0b0b;
  @media only screen and (max-width: 630px) {
    margin: 0;
  }
`;

export const TrustPilotReviewText = styled.p`
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #000000;
  text-align: center;
  margin: 0 8px 0 0;

  @media only screen and (max-width: 630px) {
    font-size: 11px;
    margin: 0 5px 0 0;
  }
`;

export const TrustPilotBadge = styled.div`
  cursor: pointer;
  padding-inline: 6px;
`;

export const HomeHeroTrustpilot = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TrustPilotBadgeTop = styled.div`
  display: flex;
  align-items: center;
`;

export const TrustPilotBadgeLogo = styled.div`
  width: 70px;
  height: 20px;
  margin: 0 0 0 6px;

  svg {
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width: 630px) {
    width: 50px;
    height: 14px;
    margin: 0;
  }
`;
export const TrustPilotRating = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 630px) {
    width: 120px;
  }
`;

export const TrustPilotRatingStar = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  background-color: #dcdce6;

  svg {
    position: relative;
    z-index: 1;
    width: 16px;
    height: 16px;
  }

  @media only screen and (max-width: 630px) {
    width: 12px;
    height: 12px;

    svg {
      position: relative;
      z-index: 1;
      width: 10px;
      height: 10px;
    }
  }
`;

export const TrustPilotRatingBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  ${({ bg, bgColor = `#DCDCE6` }) => {
    switch (bg) {
      case 'half':
        return `
          width: 50%;
          background-color: ${bgColor};
        `;
      case 'whole':
        return `
          background-color: ${bgColor};
        `;
      default:
        return ``;
    }
  }}
`;
